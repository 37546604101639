import { jsx as i } from "@emotion/react/jsx-runtime";
import g from "@emotion/styled";
import x from "@mui/icons-material/Close";
import { debounce as S } from "@mui/material/utils";
import { useState as l, useEffect as C, useMemo as I } from "react";
import { T as b } from "../utils/TextField.js";
const y = g(b)({
  marginBottom: 10,
  input: {
    minWidth: "250px",
    padding: "10px 15px"
  }
});
function v({
  handleChange: e,
  handleClear: n,
  labelText: p,
  debounceTime: r = 1e3,
  variant: c,
  enableClear: o = !1,
  placeholder: m
}) {
  const [t, a] = l(""), [u, d] = l(o);
  C(() => d(o && !!t), [o, t]);
  const h = () => {
    a(""), n && n();
  }, f = I(() => S(e, r), [e, r]);
  return /* @__PURE__ */ i(
    y,
    {
      onChange: (s) => {
        a(s.target.value), f(s);
      },
      label: p,
      variant: c,
      value: t,
      placeholder: m,
      InputProps: {
        endAdornment: u && /* @__PURE__ */ i(
          x,
          {
            sx: { cursor: "pointer", position: "absolute", right: 10 },
            onClick: h,
            "aria-label": "search-clear-button"
          }
        ),
        style: {
          paddingRight: 0
        }
      },
      InputLabelProps: {
        style: { marginTop: "-5px" }
      }
    }
  );
}
export {
  v as S
};
